import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "petoffice-handbuch",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#petoffice-handbuch",
        "aria-label": "petoffice handbuch permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`petoffice Handbuch`}</h1>
    <p>{`petoffice ist die erste Software, die den Schwerpunkt auf die Koordination und Abwicklung der Vermittlung von Tieren im In- und Ausland legt,
mit dem Ziel, diese Prozesse zu verbessern und mit neuster Technologie so effizient und einfach wie möglich zu gestalten.`}</p>
    <p>{`Hier findest du alles, was du benötigst, um mit petoffice loszulegen. Von den ersten Schritten bis zu den kleinsten Details
für alle Funktionen beschreiben wir auf den folgenden Seiten, was du tun musst, um den Alltag in deinem Verein effizienter zu
gestalten und somit mehr Zeit zu haben, dich um den Schutz der Tiere zu kümmern.`}</p>
    <p>{`Wir sind ständig dabei, diese Seiten auszubauen, um dir bessere Tips und Ratschläge zur Benutzung geben zu können. Solltest
du wider Erwarten nicht fündig werden, dann melde dich gerne bei uns.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      